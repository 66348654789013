import React, { useEffect, useState } from "react";
import { Container, Form, Button } from "semantic-ui-react";
import "../assets/css/profile.css";
import { useAuth } from "../contexts/AuthContext";
import axios from "../api/axios";
import head from "../assets/img/head.png";
import { notify } from "../utils/notification";
import { badgeLevels } from "../utils/badgeRank";


function Profile() {
  const { tle__user, setTle__user } = useAuth();
  const [code, setCode] = useState(null);
  const [loading, setLoading] = useState(false);
  const [userrefs, setUserrefs] = useState([]);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const data = {
          user_id: tle__user.user_id,
        };
        const response = await axios.post("/user", data);
        setTle__user(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    const fetchUserref = async () => {
      try {
        const data = {
          user_id: tle__user.user_id,
        };
        const response = await axios.post("/user-ref", data);
        setUserrefs(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchUser();
    fetchUserref();
  }, [tle__user.user_id, setTle__user]);

  const handleCopy = () => {
    const textToCopy = tle__user ? tle__user.user_id : "N/A";
    navigator.clipboard.writeText(textToCopy).then(() => {
      notify('Copied to clipboard!');
    }).catch(err => {
      console.error('Failed to copy: ');
      notify('Failed to copy!');
    });
  };


  async function updateRef(e) {
    e.preventDefault();
    setLoading(true);
    try {
      const data = {
        user_id: tle__user.user_id,
        ref_id: code,
      };
      const response = await axios.post("/user/upadate-ref", data);
      notify(response.data.message);
      setLoading(false);
    } catch (error) {
      console.error(error);
      notify(error.response.data.error);
      setLoading(false);
    }
  }



  const calculateProgress = (points) => {
    const currentBadge = badgeLevels.find(
      (badge) => points >= badge.min && points <= badge.max
    );
    const nextBadge = badgeLevels.find(
      (badge) => badge.min === currentBadge.max + 1
    );

    const progress =
      nextBadge !== undefined
        ? ((points - currentBadge.min) / (nextBadge.min - currentBadge.min)) *
          100
        : 100;

    return progress;
  };

  const progress = tle__user ? calculateProgress(tle__user.points) : 0;

  return (
    <Container>
      <section className="py-5">
        <div className="text-center">
          <img src={head} alt="" className="nav__img" />
          <h3>
            {tle__user ? tle__user.username || "Unknown" : "Unknown"}'s Profile
          </h3>
        </div>
      </section>

      <section className="py-3">
        <div className="badge__container">
          <div className="badge__progress text-center">
            <h3 className="text-center">TLE Earned</h3>
            <span>
              <b>{tle__user ? tle__user.points : 0} TLE</b>
            </span>

            <div className="progress mt-4 mt-md-5">
              <div
                id="percentageBar"
                className="progress-bar"
                role="progressbar"
                aria-valuenow={progress}
                aria-valuemin="0"
                aria-valuemax="100"
                style={{
                  width: `${progress}%`,
                }}
              >
                {progress.toFixed(0)}%
              </div>
            </div>
          </div>
          <h3 className="mt-5">Badges Achieved</h3>
          <div className="badges">
            {tle__user &&
              tle__user.badges.map((badge) => {
                const badgeLevel = badgeLevels.find(
                  (level) => level.status === badge.status
                );
                return (
                  <div
                    key={badge.status}
                    className={`badge__item ${badge.status}`}
                    style={{
                      color: badge.achieved ? badgeLevel.color : "grey",
                      borderColor: badge.achieved ? badgeLevel.color : "grey",
                    }}
                  >
                    <i className={badgeLevel.icon}></i>
                    <span>
                      {badge.status.charAt(0).toUpperCase() +
                        badge.status.slice(1)}
                    </span>
                  </div>
                );
              })}
          </div>
        </div>
      </section>

      {tle__user && tle__user.ref_id ? (
        <></>
      ) : (
        <section className="py-3">
          <div className="user__page">
            <h3 className="text-center">Apply code</h3>
            <div className="code__space">
              <form onSubmit={updateRef} className="form-inline">
                <input
                  onChange={(e) => setCode(e.target.value)}
                  className="code__input"
                  placeholder="Enter code here"
                  type="text"
                  value={code}
                  required
                />
                <Button
                  loading={loading}
                  type="submit"
                  style={{
                    backgroundColor: "#597DC44D",
                    color: "#3F7BE0",
                    borderRadius: "15px",
                  }}
                  className="apply__btn"
                >
                  Apply
                </Button>
              </form>
            </div>
          </div>
        </section>
      )}

      <section className="py-3">
        <div className="user__page">
          <h3 className="text-center">Refer a Friend</h3>
          <p className="text-center">Your referral code</p>

          <div className="code__space">
            <span>{tle__user ? tle__user.user_id : "N/A"}</span>
            <button className="copy__btn" onClick={handleCopy}>Copy</button>
          </div>

          <div className="mt-5">
            <b>Friends Referred</b> <span className="ref__not">{userrefs.length}</span>
            <div className="ref__list mt-2 code__space">
              {userrefs.map((user) => (
                <div key={user} className="ref__item ">
                   <div>
                   {/* <img src='' alt="" /> */}
                   <i class="fa fa-user-circle-o" aria-hidden="true"></i>
                   <span style={{color: "#fff"}}>{user.username}</span>
                   </div>

                  <span style={{color: "green"}}>+100xp</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <section className="py-5"></section>
    </Container>
  );
}

export default Profile;
