import React, { createContext, useContext, useState, useEffect } from "react";
import WebApp from "@twa-dev/sdk";
import axios from "../api/axios";
import { Loader } from "semantic-ui-react";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [tgData, setTgData] = useState(null);
  const [tle__user, setTle__user] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async (user_id) => {
      try {
        const data = {
          user_id,
        };
        const response = await axios.post("/user", data);
        setTle__user(response.data);
        setTimeout(() => {
          setLoading(false);
        }, 5000);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    // fetchData(92763);
    // setTgData({
    //   id: 92763,
    //   username: "TestV4",
    //   first_name: "Ton",
    //   last_name: "Learn",
    //   photo_url: "https://t.me/i/userpic/320/4yf9wQ7ZqT4.jpg",
    // });

    if (WebApp.initDataUnsafe.user) {
      const user = WebApp.initDataUnsafe.user;
      setTgData(user);
      fetchData(user.id);
    }
  }, []);

  if (loading) {
    return (
      <div className="text-center py-5 mt-5">
        <Loader size="large" active />
      </div>
    );
  }

  return (
    <AuthContext.Provider value={{ tgData, tle__user, setTle__user }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
