import React from "react";
import { useAuth } from "../contexts/AuthContext";
import { Navigate } from "react-router-dom";

function Authenticated({ children }) {
  const { tgData, tle__user } = useAuth();

  if (!tgData) {
    return (
      <section className="py-5 mt-5 text-center">
        <h1>Unauthorized</h1>
        <p className="text-center">
          You are not authorized to use this site, please contact the team
        </p>
      </section>
    );
  }

  if (!tle__user) {
    return <Navigate to={`/register`} />;
  }

  return <div>{children}</div>;
}

export default Authenticated;
