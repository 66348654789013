import Toastify from "toastify-js";

export const notify = (message) => {
  Toastify({
    text: `${message}`,
    duration: 6000,
    newWindow: true,
    close: true,
    gravity: "bottom",
    position: "center",
    stopOnFocus: true,
    style: {
      background: "linear-gradient(to right, #181818, #181818)",
      borderRadius: "10px",
      bottom: "800px",
      maxWidth: "100%",
      fontSize: "16px",
      padding: "15px 20px",
      color: "#3F7BE0",
      textAlign: "center",
      fontWeight: "bolder",
    },
    onClick: function () {},
  }).showToast();
};
