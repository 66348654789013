export const badgeLevels = [
    { status: "novice", min: 0, max: 500, icon: "fa-regular fa-lightbulb" },
    { status: "senior", min: 501, max: 2500, icon: "fa-regular fa-lightbulb" },
    {
      status: "enthusiast",
      min: 2501,
      max: 12500,
      icon: "fa-regular fa-lightbulb",
    },
    {
      status: "leader",
      min: 12501,
      max: 62500,
      icon: "fa-solid fa-star-of-life",
    },
    {
      status: "expert",
      min: 62501,
      max: 312500,
      icon: "fa-regular fa-star-half",
    },
    {
      status: "veteran",
      min: 312501,
      max: 1562500,
      icon: "fa-regular fa-star-half-stroke",
    },
    {
      status: "master",
      min: 1562501,
      max: 7812500,
      icon: "fa-solid fa-shield-halved",
    },
    {
      status: "ultimate",
      min: 7812501,
      max: Infinity,
      icon: "fa-solid fa-shield",
    },
  ];
