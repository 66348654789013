import React, { useEffect, useState } from "react";
import { Button, Container, Loader } from "semantic-ui-react";
import "../assets/css/task.css";
import { useAuth } from "../contexts/AuthContext";
import axios from "../api/axios";
import { notify } from "../utils/notification";


function Tasks() {
  axios.defaults.withCredentials = true;
  const { tle__user } = useAuth();
  const [tasks, setTasks] = useState([]);
  const [taskStatus, setTaskStatus] = useState(false);
  const [loadingTasks, setLoadingTasks] = useState({});
  const [claimingTasks, setClaimingTasks] = useState({});
  const [userrefs, setUserrefs] = useState([]);

  useEffect(() => {
    const fetchTasks = async () => {
      try {
        const response = await axios.get("/tasks");
        console.log(response.data)
        setTasks(response.data);
        setTaskStatus(true);
      } catch (error) {
        console.error(error);
        setTaskStatus(false);
      }
    };

    const fetchUserref = async () => {
      try {
        const data = {
          user_id: tle__user.user_id,
        };
        const response = await axios.post("/user-ref", data);
        setUserrefs(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchUserref();
    fetchTasks();
  }, []);

  const handleTaskLink = (taskId, url) => {
    setLoadingTasks((prev) => ({ ...prev, [taskId]: true }));

    if (taskId === "66be12f0509b09b44d0a8d91") {
      if (userrefs.length >= 1) {
        setTimeout(() => {
          setLoadingTasks((prev) => ({ ...prev, [taskId]: false }));
          setClaimingTasks((prev) => ({ ...prev, [taskId]: true }));
        }, 5000);

      } else {
        notify("Requirements failed")
        setLoadingTasks((prev) => ({ ...prev, [taskId]: false }));
        setClaimingTasks((prev) => ({ ...prev, [taskId]: false }));
      }

    } else if (taskId == "66be1327509b09b44d0a8d92") {
      if (userrefs.length >= 5) {
        setTimeout(() => {
          setLoadingTasks((prev) => ({ ...prev, [taskId]: false }));
          setClaimingTasks((prev) => ({ ...prev, [taskId]: true }));
        }, 5000);

      } else {
        notify("Requirements failed")
        setLoadingTasks((prev) => ({ ...prev, [taskId]: false }));
        setClaimingTasks((prev) => ({ ...prev, [taskId]: false }));
      }

    } else if (taskId === "66be133c509b09b44d0a8d93") {
      if (userrefs.length >= 10) {
        setTimeout(() => {
          setLoadingTasks((prev) => ({ ...prev, [taskId]: false }));
          setClaimingTasks((prev) => ({ ...prev, [taskId]: true }));
        }, 5000);

      } else {
        notify("Requirements failed")
        setLoadingTasks((prev) => ({ ...prev, [taskId]: false }));
        setClaimingTasks((prev) => ({ ...prev, [taskId]: false }));
      }

    }

    else if (taskId === "66c076f04531587c789c86bb") {

      if (tle__user.points >= 12501) {

        setTimeout(() => {
          setLoadingTasks((prev) => ({ ...prev, [taskId]: false }));
          setClaimingTasks((prev) => ({ ...prev, [taskId]: true }));
        }, 5000);
      } else {

        notify("Requirements failed")
        setLoadingTasks((prev) => ({ ...prev, [taskId]: false }));
        setClaimingTasks((prev) => ({ ...prev, [taskId]: false }));

      }

    }


    else {

      window.open(url, "_blank");
      setTimeout(() => {
        setLoadingTasks((prev) => ({ ...prev, [taskId]: false }));
        setClaimingTasks((prev) => ({ ...prev, [taskId]: true }));
      }, 5000);


    }



  };

  async function handleClaimTask(taskId) {
    setLoadingTasks((prev) => ({ ...prev, [taskId]: true }));
    const data = {
      task_id: taskId,
      user_id: tle__user.user_id,
    };

    try {
      const response = await axios.post(`/perform-task`, data);
      setLoadingTasks((prev) => ({ ...prev, [taskId]: false }));

      setClaimingTasks((prev) => ({ ...prev, [taskId]: false }));
      setTasks((prev) =>
        prev.map((task) =>
          task._id === taskId
            ? { ...task, users: [...task.users, tle__user.user_id] }
            : task
        )
      );
      notify(response.data.message);
    } catch (error) {
      console.error(error.response.data.error);
      notify(error.response.data.error);
      setLoadingTasks((prev) => ({ ...prev, [taskId]: false }));
      setClaimingTasks((prev) => ({ ...prev, [taskId]: false }));
    }
  }

  if (!taskStatus) {
    return (
      <div className="text-center py-5 mt-5">
        <Loader size="large" active />
      </div>
    );
  }

  return (
    <Container>
      <section className="py-5">
        <div className="task__container">
          <h2>TASKS</h2>
          {tasks && tasks.length > 0 ? (
            <>
              {tasks.map((tsk) => {
                const isLoading = loadingTasks[tsk._id];
                const isClaiming = claimingTasks[tsk._id];
                const hasClaimed = tsk.users.includes(tle__user.user_id);

                return (
                  <div className="task__item" key={tsk._id}>
                    <div>
                      <i className={tsk.task_icon}></i>
                      <b>{tsk.task_title}</b>
                    </div>

                    {hasClaimed ? (
                      <div className="btn__link">
                        <Button
                          style={{
                            backgroundColor: "#5D5D5DD6",
                            color: "#fff",
                            weight: "500",
                            fontSize: "10px",
                          }}
                          disabled
                        >
                          claimed
                        </Button>
                      </div>
                    ) : (
                      <div className="btn__link">
                        {isClaiming ? (
                          <Button
                            onClick={() => handleClaimTask(tsk._id)}
                            style={{
                              backgroundColor: "#3F7BE0",
                              color: "#fff",
                              weight: "500",
                              fontSize: "10px",
                            }}
                          >
                            Claim +{tsk.task_point}
                          </Button>
                        ) : (
                          <Button
                            loading={isLoading}
                            onClick={() =>
                              handleTaskLink(tsk._id, tsk.task_url)
                            }
                            style={{
                              backgroundColor: "#3F7BE0",
                              color: "#fff",
                              weight: "500",
                              fontSize: "10px",
                            }}
                          >
                            {tsk.key_word}
                          </Button>
                        )}
                      </div>
                    )}
                  </div>
                );
              })}
            </>
          ) : (
            <>
              <h1 className="text-center">NO TASKS</h1>
            </>
          )}
        </div>
      </section>
    </Container>
  );
}

export default Tasks;
