import React, { useEffect, useState } from "react";
import { Button } from "semantic-ui-react";
import "../assets/css/quiz.css";
import Success from "./Success";
import axios from "../api/axios";
import { useAuth } from "../contexts/AuthContext";

function QuizLoader({ post, state }) {
  const { tle__user } = useAuth();
  const [complete, setComplete] = useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);
  const [userAnswers, setUserAnswers] = useState([]);
  const [results, setResults] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log(post)
  })

  const questions = post.questions;

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
  };

  const handleNextClick = async () => {
    const newUserAnswers = [...userAnswers];
    newUserAnswers[currentQuestionIndex] = selectedOption;
    setUserAnswers(newUserAnswers);
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      setSelectedOption(newUserAnswers[currentQuestionIndex + 1] || null);
    } else {
      const calculatedResults = calculateResults(newUserAnswers);
      setResults(calculatedResults);
      await updateUserPoints(calculatedResults.earnedReward);
      setComplete(true);
    }
  };

  const handleBackClick = () => {
    if (currentQuestionIndex > 0) {
      const newUserAnswers = [...userAnswers];
      newUserAnswers[currentQuestionIndex] = selectedOption;
      setUserAnswers(newUserAnswers);
      setCurrentQuestionIndex(currentQuestionIndex - 1);
      setSelectedOption(newUserAnswers[currentQuestionIndex - 1] || null);
    }
  };

  
  const calculateResults = (answers) => {
    let correctAnswers = 0;
    let wrongAnswers = 0;
    answers.forEach((answer, index) => {
      if (answer === questions[index].answer) {
        correctAnswers++;
      } else {
        wrongAnswers++;
      }
    });

    const totalReward = post.reward;
    const rewardPerQuestion = totalReward / questions.length;
    const earnedReward = rewardPerQuestion * correctAnswers;

    return {
      totalQuestions: questions.length,
      correctAnswers,
      wrongAnswers,
      earnedReward,
    };
  };

  async function updateUserPoints(earnedReward) {
    setLoading(true)
    console.log(earnedReward)
    try {
      const data = {
        post_id: post._id,
        user_id: tle__user.user_id, // Use appropriate user ID
        reward: earnedReward,
      };
      const response = await axios.post(`/post-attempts`, data);
      console.log(response);
      setLoading(false)
    } catch (err) {
      console.log(err);
      setLoading(false)
    }
  }

  const currentQuestion = questions[currentQuestionIndex];

  return (
    <>
      {complete ? (
        <Success post={post} results={results} />
      ) : (
        <section className="">
          <div className="quiz__card">
            <div className="quiz__nav">
              <Button
                className="back__btn"
                onClick={handleBackClick}
                disabled={currentQuestionIndex === 0}
              >
                <i className="fa-solid fa-arrow-left"></i>
              </Button>
              <h3>
                {currentQuestionIndex + 1} out of {questions.length}
              </h3>
              <Button className="back__btn" onClick={() => state(false)}>
                X
              </Button>
            </div>

            <div className="progressBar">
              <div className="progress mt-4 mt-md-5">
                <div
                  id="percentageBar"
                  className="progress-bar"
                  role="progressbar"
                  aria-valuenow={
                    ((currentQuestionIndex + 1) / questions.length) * 100
                  }
                  aria-valuemin="0"
                  aria-valuemax="100"
                  style={{
                    width: `${
                      ((currentQuestionIndex + 1) / questions.length) * 100
                    }%`,
                  }}
                >
                  {((currentQuestionIndex + 1) / questions.length) * 100}%
                </div>
              </div>
            </div>

            <div className="question__card">
              <h3>{currentQuestion.question}</h3>

              <div className="options">
                {currentQuestion.options.map((option, index) => (
                  <div
                    key={index}
                    className={`option__item ${
                      selectedOption === option ? "selected" : ""
                    }`}
                    onClick={() => handleOptionSelect(option)}
                    style={{
                      backgroundColor:
                        selectedOption === option ? "#214DA3" : "",
                      color: selectedOption === option ? "#fff" : "",
                    }}
                  >
                    <b>{option}</b>
                  </div>
                ))}
              </div>
            </div>

            <Button
              fluid
              loading={loading}
              className="mt-5"
              style={{
                backgroundColor: "#214DA3",
                color: "#E9ECF0",
                borderRadius: "8px",
              }}
              onClick={handleNextClick}
              disabled={!selectedOption} // Disable button if no option is selected
            >
              {currentQuestionIndex < questions.length - 1 ? "Next" : "Submit"}
            </Button>
          </div>

          <div className="py-5"></div>
        </section>
      )}
    </>
  );
}

export default QuizLoader;
