import React from "react";
import { Button } from "semantic-ui-react";
import cup from "../assets/img/winner.png";
import '../assets/css/success.css';
import { Link } from "react-router-dom";

function Success({ post, results }) {
  return (
    <React.Fragment>
      <section className="py-3">
        <div className="summery__nav">
          <h3>Quiz Summary</h3>
          <Link to="/">
            <Button className="back__btn">X</Button>
          </Link>
        </div>
      </section>

      <section>
        <div className="winner__bar">
          <img src={cup} alt="Winner" />
          <h3 className="text-center">CONGRATULATIONS!</h3>
          <p className="text-center">You’ve earned {results.earnedReward} TLE</p>

          <div className="winner__classes">
            <div className="win__items">
              <i className="Q">Q</i>
              <span>{results.totalQuestions}</span>
              <p>Total quiz</p>
            </div>

            <div className="win__items">
              <i className="fa-solid fa-file-circle-check"></i>
              <span>{results.correctAnswers}</span>
              <p>Correct</p>
            </div>

            <div className="win__items">
              <i className="fa-solid fa-file-circle-xmark"></i>
              <span>{results.wrongAnswers}</span>
              <p>Wrong</p>
            </div>
          </div>
        </div>
      </section>

    

      <section>
  <div className="quiz__ranking">
    <h3 className="text-center">TOP 10 Ranking</h3>

    <div className="leaderboard">
      {post.user_attempts ? (
        post.user_attempts
          .sort((a, b) => b.reward - a.reward) // Sort by reward in descending order
          .slice(0, 10) // Take the top 10
          .map((e) => (
            <div className="leader__items" key={e.user_id}>
              <div>
                {/* <img
                  src="https://s3-alpha-sig.figma.com/img/b2c3/870d/2e9a53bac3b9c2ee51f9a130b8384c0b?Expires=1723420800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=QQjf09UoNhfCz~dSboQFIFkLLp8a~OL-40Fnk4B2ZEQpzH8Xv361wRjQlrcerhWBvmpxSrC17K6lS2lwx57LE4sIlEItWYqHOLqnqJd274IKWPeqOxPBTywfPXwLcKrEVr6KQJWI8fUmIx8~82H9ECVJVu937bjYrjEa~22-tnkR7k5Qy3ukISkM22KtFdqVLkTUnbZrZ4dhR8vNulmoti~qCfZGXH7fdF39Xwj~cBs8eJKyKcP8sOmxTVfUxBYYgC2sIU38-uRkuY8JEIUNp1bCPXdGtapNN2tVb-3Q2G~yjn47xolipk3ABmRWV7Ry1Bq4tc4JGGUs-RpCI3ZQTA__"
                  alt="."
                /> */}
                <span>{e.username}</span>
              </div>
              <p>{e.reward} TLE</p>
            </div>
          ))
      ) : null}
    </div>
  </div>
</section>



      <div className="py-5"></div>
    </React.Fragment>
  );
}

export default Success;
