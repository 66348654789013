import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

function IncomingCarousel() {
  return (
    <section className="py-5">
      <h3>INCOMING</h3>
      <Carousel
        showThumbs={true}
        infiniteLoop={true}
        autoPlay={true}
        interval={6000}
        showStatus={false}
        showIndicators={false}
      >







        <div className="inc__container">
          <img
            src="https://res.cloudinary.com/duume45sb/image/upload/v1725872717/1000563104_e7d4hh.jpg"
            alt="."
          />
          <h4>
            TON BATTLE
          </h4>
          <p>
            Introducing   TON BATTLE. The Competitive Gaming Arena on TON.
          </p>
        </div>



        <div className="inc__container">
          <img
            src="https://res.cloudinary.com/duume45sb/image/upload/v1725872715/1000563100_xqslnr.jpg"
            alt="."
          />
          <h4>GET GEMS</h4>
          <p>
            Introducing GET GEMS. The Premier NFT MarketPlace on TON.
          </p>
        </div>

        <div className="inc__container">
          <img
            src="https://res.cloudinary.com/duume45sb/image/upload/v1725872716/1000563102_oswxk3.jpg"
            alt="."
          />
          <h4>
            TON DAIMONDS
          </h4>
          <p>
            Introducing  TON DAIMONDS. The Unique NFT collection on TON Blockchain.
          </p>

        </div>

        {/* <div className="inc__container">
          <img
            src="https://res.cloudinary.com/duume45sb/image/upload/v1725872716/1000563096_xzik8b.jpg"
            alt="."
          />
          <h4>
            STONFI
          </h4>
          <p>
            Introducing STONFI. The Leading Decentralized Exchange on TON Blockchain.
          </p>
        </div>

        <div className="inc__container">
          <img
            src="https://res.cloudinary.com/duume45sb/image/upload/v1725872718/1000563098_oyrvzz.jpg"
            alt="."
          />
          <h4>
            MEGATON FINANCE
          </h4>
          <p>
            Introducing MEGATON FINANCE. The Autonomous Financial Protocol on TON Blockchain.
          </p>
        </div> */}




        {/*  */}

      </Carousel>

    </section>
  )
}

export default IncomingCarousel;
