import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import "../assets/css/styles.css";
import logo from "../assets/img/brand.png";


function Layout({ children }) {
  return (
    <div>
      <header className="header" id="header">
        <nav className="nav container">
          <NavLink to="/" className="nav__logo">
            <img src={logo} alt="Logo" className="nav__brand__img" />
          </NavLink>

          <div className="nav__menu" id="nav-menu">
            <ul className="nav__list">
              <li className="nav__item">
                <NavLink exact to="/" className="nav__link" activeClassName="active">
                  <i className="bx bx-home-alt nav__icon"></i>
                </NavLink>
              </li>

              <li className="nav__item">
                <NavLink to="/tasks" className="nav__link" activeClassName="active">
                  <i className="fa-solid fa-bars-staggered nav__icon"></i>
                </NavLink>
              </li>
              {/*   
                <li className="nav__item">
                  <NavLink to="/post/1" className="nav__link" activeClassName="active">
                    <i className="bx bx-book-alt nav__icon"></i>
                  </NavLink>
                </li> */}

              <li className="nav__item">
                <NavLink to="/user" className="nav__link" activeClassName="active">
                  <i className="bx bx-user nav__icon"></i>
                </NavLink>
              </li>
            </ul>
          </div>

          {/* <i class="fa-solid fa-grip-vertical fa-lg"></i> */}
        </nav>
      </header>
      <main>{children}</main>
    </div>
  );
}

export default Layout;
