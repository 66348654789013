import React from "react";
import { Button, Container } from "semantic-ui-react";
import axios from "../api/axios";
import { useNavigate } from "react-router-dom";
import "../assets/css/profile.css";
import { notify } from "../utils/notification";
import { useAuth } from "../contexts/AuthContext";
import { useSearchParams } from 'react-router-dom';
import logo from "../assets/img/brand.png";
import head from "../assets/img/head.png";

function Register() {
  const [searchParams] = useSearchParams();
  const ref_id = searchParams.get('ref');
  const { tgData } = useAuth();
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();

  async function createAccount() {
    setLoading(true);
    try {
      const data = {
        user_id: tgData?.id,
        username: tgData?.username,
        first_name: tgData?.first_name,
        last_name: tgData?.last_name,
        ref_id: Number(ref_id) || null,
      };
      const response = await axios.post("/register", data);
      console.log(response.data);
      notify(response.data.message);
      setTimeout(() => {
        navigate("/");
        window.location.reload();
      }, 3000);
    } catch (error) {
      console.log(error.response?.data);
      notify(error.response?.data?.error);
      if (error.response?.data?.error === "user already exists") {
        window.location.reload();
      }
      setLoading(false);
    }
  }

  return (
    <Container>

      <div className="py-5 mt-5">
    

    <div className="images__trf">
    <img src={logo} alt="." className="img-fluid brand__img" />
    <i class="fa-solid fa-right-left"></i>
        

        {tgData && tgData.photo ?   <img
            src={String(tgData.photo)}
            alt="user"
            className="profile__image"
          /> :    <img
          src={head}
          alt="user"
          className="profile__image"
        />}
    </div>


        <h1 className="text-center">
          Hey {tgData ? tgData.username || "User" : "User"}
        </h1>

      
        <h3 className="text-center">Welcome to Ton Learn And Earn</h3>
      </div>

      <div className="mt-2">
        <div className="text-center">
          <i>
            {ref_id
              ? `You are invited by ${ref_id}`
              : "You are invited by Ton Learn And Earn"}
          </i>
        </div>
        <Button
          className="mt-2"
          style={{
            backgroundColor: "#597DC44D",
            color: "#3F7BE0",
            borderRadius: "15px",
          }}
          fluid
          loading={loading}
          onClick={createAccount}
        >
          <i className="fa-solid fa-rocket"></i> Create An Account
        </Button>
      </div>
    </Container>
  );
}

export default Register;
