import React, { useEffect, useState } from "react";
import { Button, Container, Loader } from "semantic-ui-react";
import { useParams, useNavigate } from "react-router-dom";
import "../assets/css/post.css";
import QuizeLoader from "../component/QuizeLoader";
import axios from "../api/axios";
import { useAuth } from "../contexts/AuthContext";

function Post() {
  const { tle__user } = useAuth();
  const { id } = useParams();
  const navigate = useNavigate();
  const [fetching, setFetching] = useState(true);
  const [hidden, setHidden] = useState(true);
  const [quiz, setQuiz] = useState(false);
  const [post, setPost] = useState({});

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(`/post/${id}`);
        setPost(response.data);
        setFetching(false);
      } catch (error) {
        console.error(error);
        navigate("/");
      }
    }
    fetchData();
  }, [id, navigate]);

  function reSetContent() {
    if (hidden) {
      setHidden(false);
    } else {
      setHidden(true);
    }
  }

  return (
    <Container>
      {fetching ? (
        <>
          <div className="loader">
            <div className="text-center py-5 mt-5">
              <Loader size="large" active></Loader>
            </div>
          </div>
        </>
      ) : (
        <>
          {" "}
          {quiz ? (
            <>
              <QuizeLoader post={post} state={setQuiz} />
            </>
          ) : (
            <>
              {post ? (
                <>
                  <button
                    onClick={() => navigate(-1)}
                    className="navigate_back_button"
                  >
                    <i className="fa-solid fa-arrow-left navigate_back"></i>
                  </button>
                  <section className="py-5" key={post._id}>
                    <div className="cont">
                      <div id="content__type">
                        <b>Type: {post.content_type}</b>
                        <b>Reward: {post.reward} TLE </b>
                      </div>

                      <div id="content__title" className="mt-4 mb-2">
                        <h1>{post.header}</h1>
                      </div>

                      <span className="short__header">{post.short_desc}</span>

                      <div id="content__bodt">
                        {/* <p>{post.content}</p> */}

                        {post.content.split('\n').map((paragraph, index) => (
                          <p key={index}>{paragraph}</p>
                        ))}
                      </div>

                      <div className="content__footer">
                        <div>
                          <span>1 lesson</span>
                          <span className="duration">
                            {" "}
                            {post.duration}secs{" "}
                          </span>
                        </div>

                        <p>Participants: {post.user_attempts.length} </p>
                      </div>
                    </div>

                    <div className="read__more" onClick={reSetContent}>
                      <i class="fa-brands fa-readme"></i>
                    </div>

                    <div className="py-5">
                      {post.user_attempts &&
                        post.user_attempts.some(
                          (e) => e.user_id === tle__user.user_id
                        ) ? (
                        post.user_attempts.map((e) => {
                          if (e.user_id === tle__user.user_id) {
                            return (
                              <div className="user__attempt" key={e.user_id}>
                                <Button
                                  fluid
                                  className="StartBtn"
                                  disabled
                                  style={{
                                    backgroundColor: "#597DC44D",
                                    color: "#3F7BE0",
                                    borderRadius: "15px",
                                  }}
                                >
                                  <i className="fa-solid fa-rocket"></i>{" "}
                                  Completed + {e.reward}
                                </Button>
                              </div>
                            );
                          } else {
                            return null;
                          }
                        })
                      ) : (
                        <Button
                          onClick={() => setQuiz(true)}
                          fluid
                          className="StartBtn"
                          style={{
                            backgroundColor: "#597DC44D",
                            color: "#3F7BE0",
                            borderRadius: "15px",
                          }}
                        >
                          <i className="fa-solid fa-rocket"></i> Start Quiz
                        </Button>
                      )}
                    </div>
                  </section>
                </>
              ) : (
                <>
                  <h1>POST NOT FOUND</h1>
                </>
              )}
            </>
          )}
        </>
      )}
    </Container>
  );
}

export default Post;
