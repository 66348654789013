import React, { useState } from 'react';
import { Form, Button, Input, TextArea, Container } from 'semantic-ui-react';
import axios from '../api/axios';
import { notify } from '../utils/notification';

function CreatePostForm() {
  axios.defaults.withCredentials = true;

  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState({
    header: '',
    short_desc: '',
    content: '',
    duration: 0,
    logo: '',
    img: '',
    content_type: '',
    reward: 0,
    code: '',
    list_of_questions: [{ question: '', options: [], answer: '' }]
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
        ...formData,
        [name]: name === 'duration' || name === 'reward' ? Number(value) : value
    });
};

const handleQuestionChange = (index, e) => {
  const { name, value } = e.target;
  const updatedQuestions = formData.list_of_questions.map((question, qIndex) => {
      if (index === qIndex) {
          return {
              ...question,
              [name]: name === 'options' ? value.split(',').map(option => option.trim()) : value
          };
      }
      return question;
  });
  setFormData({ ...formData, list_of_questions: updatedQuestions });
};


const handleAddQuestion = () => {
  setFormData({
      ...formData,
      list_of_questions: [...formData.list_of_questions, { question: '', options: [], answer: '' }]
  });
};

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post("/create-post", formData);
      console.log(response)
      notify('Post created successfully');
      setLoading(false);
    } catch (error) {
      console.error(error);
      notify(error.response?.data?.error)
      setLoading(false);

    }
  };

  return (
    <Container className='py-5'>
      <Form onSubmit={handleSubmit} style={{ maxWidth: '600px', margin: 'auto' }}>
        <Form.Field>
          <p>Header</p>
          <Input
            name="header"
            placeholder="Header"
            value={formData.header}
            onChange={handleChange}
            required
          />
        </Form.Field>

        <Form.Field>
          <p>Short Description</p>
          <Input
            name="short_desc"
            placeholder="Short Description"
            value={formData.short_desc}
            onChange={handleChange}
            required
          />
        </Form.Field>

        <Form.Field>
          <p>Content</p>
          <TextArea
            name="content"
            placeholder="Content"
            value={formData.content}
            onChange={handleChange}
            required
          />
        </Form.Field>

        <Form.Field>
          <p>Duration (in secs)</p>
          <Input
            type="number"
            name="duration"
            placeholder="Duration"
            value={formData.duration}
            onChange={handleChange}
            required
          />

        </Form.Field>

        <Form.Field>
          <p>Project Logo</p>
          <Input
            name="logo"
            placeholder="project logo URL"
            value={formData.logo}
            onChange={handleChange}
            required
          />
        </Form.Field>

        <Form.Field>
          <p>Image URL</p>
          <Input
            name="img"
            placeholder="Image URL"
            value={formData.img}
            onChange={handleChange}
            required
          />
        </Form.Field>

        <Form.Field>
          <p>Content Type</p>
          <Input
            name="content_type"
            placeholder="Content Type"
            value={formData.content_type}
            onChange={handleChange}
            required
          />
        </Form.Field>

        <Form.Field>
          <p>Reward</p>
          <Input
            type="number"
            name="reward"
            placeholder="Reward"
            value={formData.reward}
            onChange={handleChange}
            required
          />
        </Form.Field>

        <Form.Field>
          <p>Code</p>
          <Input
            type="text"
            name="code"
            placeholder="Authorization Code"
            value={formData.code}
            onChange={handleChange}
            required
          />
        </Form.Field>

        <h4>Questions</h4>
        {formData.list_of_questions.map((question, index) => (
          <div key={index} style={{ marginBottom: '20px' }}>
            <Form.Field>
              <p>Question</p>
              <Input
                name="question"
                placeholder="Question"
                value={question.question}
                onChange={(e) => handleQuestionChange(index, e)}
                required
              />
            </Form.Field>
            <Form.Field>
              <p>Options (comma separated)</p>
              <Input
                name="options"
                placeholder="Options"
                value={question.options}
                onChange={(e) => handleQuestionChange(index, e)}
                required
              />
            </Form.Field>
            <Form.Field>
              <p>Answer</p>
              <Input
                name="answer"
                placeholder="Answer"
                value={question.answer}
                onChange={(e) => handleQuestionChange(index, e)}
                required
              />
            </Form.Field>
          </div>
        ))}

        <Button
          type="button"
          onClick={handleAddQuestion}
          color="blue"
          style={{ marginBottom: '20px' }}
        >
          Add Question
        </Button>
        <br />
        <Button loading={loading} fluid type="submit" color="green">
          Create Post
        </Button>
      </Form>
      <div className='py-5'></div>
    </Container>
  );
}

export default CreatePostForm;
