import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Loader } from "semantic-ui-react";
import "../assets/css/home.css";
import axios from "../api/axios";
import { useAuth } from "../contexts/AuthContext";
import { badgeLevels } from "../utils/badgeRank";
import IncomingCarousel from "../component/IncomingCarousel";

function Home() {
  const { tle__user } = useAuth();
  const [fetching, setFetching] = useState(true);
  const [postsData, setPostsData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get("/posts");
        setPostsData(response.data);
        setFetching(false);
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, []);

  return (
    <Container className="py-4">
      {fetching ? (
        <div className="loader">
          <div className="text-center py-5 mt-5">
            <Loader size="large" active />
          </div>
        </div>
      ) : (
        <>
          <div className="home__header">
            <h3>HOME</h3>
            <div className="badges">
              {tle__user &&
                (() => {
                  const lastAchievedBadge = tle__user.badges
                    .slice()
                    .reverse()
                    .find((badge) => badge.achieved);

                  if (lastAchievedBadge) {
                    const badgeLevel = badgeLevels.find(
                      (level) => level.status === lastAchievedBadge.status
                    );
                    return (
                      <div
                        key={lastAchievedBadge.status}
                        className={`badge__item ${lastAchievedBadge.status}`}
                        style={{
                          color: lastAchievedBadge.achieved
                            ? badgeLevel.color
                            : "grey",
                          borderColor: lastAchievedBadge.achieved
                            ? badgeLevel.color
                            : "grey",
                        }}
                      >
                        <i className={badgeLevel.icon}></i>
                        <span>
                          {lastAchievedBadge.status.charAt(0).toUpperCase() +
                            lastAchievedBadge.status.slice(1)}
                        </span>
                      </div>
                    );
                  }

                  return null;
                })()}
            </div>
          </div>

          <IncomingCarousel />

          <section>
            <h3>Quiz</h3>
            {postsData ? (
              <>
                {postsData.slice(-1).map((post) => (
                  <Link to={`post/${post._id}`} key={post._id}>

                    <div className="mt-3">
                      <div className="item-container">
                        <div>
                          <img src={post.img} alt="" />
                        </div>
                        <div>

                          <div className="arrange__content">
                            <div>
                              <p className="short__header">{post.short_desc}</p>

                              {post.active ? (
                                <p>
                                  Status:{" "}
                                  <i className="fa-solid fa-signal status__active"></i>{" "}
                                </p>
                              ) : (
                                <p>
                                  Status:{" "}
                                  <i className="fa-solid fa-signal"></i> Ended
                                </p>
                              )}


                              <div className="lower__content">
                                <div>
                                  {post.logo ?
                                    <img src={post.logo} className="img-fluid post__logo" alt="." />


                                    : ""}
                                  <b>{post.header}</b>
                                </div>

                                <div className="left__item">

                                  <p> {post.duration}secs</p>

                                  <div className="star__item">
                                    {/* <span>
                                      <i className="fa-regular fa-bookmark"></i>
                                    </span> */}
                                    <i className="fa-regular fa-heart">

                                    </i>
                                    <i>{post.user_attempts.length}</i>
                                  </div>



                                </div>

                              </div>





                            </div>
                          </div>


                          {/*  */}
                        </div>


                      </div>
                    </div>
                  </Link>
                ))}
              </>
            ) : (
              <>
                <section className="py-5 mt-5">
                  <h1 className="text-center">NO POST FOUND</h1>
                </section>
              </>
            )}
          </section>
        </>
      )}

      <div className="py-5"></div>
    </Container>
  );
}

export default Home;
