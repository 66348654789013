import React, { } from "react";
import "./App.css";
import "semantic-ui-css/semantic.min.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Layout from "./component/Layout";
import Home from "./pages/Home";
import Post from "./pages/Post";
import Profile from "./pages/Profile";
import Tasks from "./pages/Tasks";
import Authenticated from "./component/Authenticated";
import { AuthProvider } from "./contexts/AuthContext";
import Register from "./pages/Register";
import CreatePostForm from "./pages/CreatePostForm"

function App() {
  return (
    <Router>

      <Layout>
        <Routes>
          <Route path="/auth/tle/admin/post" element={<CreatePostForm />} />
          <Route path="/" element={
            <AuthProvider>
              <Authenticated>
                <Home />
              </Authenticated>
            </AuthProvider>
          } />
          <Route path="/post/:id" element={
            <AuthProvider>
              <Authenticated>
                <Post />
              </Authenticated>
            </AuthProvider>
          } />
          <Route path="/user" element={
            <AuthProvider>
              <Authenticated>
                <Profile />
              </Authenticated>
            </AuthProvider>
          } />
          <Route path="/tasks" element={
            <AuthProvider>
              <Authenticated>
                <Tasks />
              </Authenticated>
            </AuthProvider>
          } />
          <Route path="/register" element={
            <AuthProvider>
              <Register />
            </AuthProvider>

          } />

        </Routes>

      </Layout>

    </Router>
  );
}

export default App;
